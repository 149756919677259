<template>
    <QSubmenu
        :title="getTitle"
        :submenu="getSubmenu"
        :buttons="getButtons"
        :titlelink="titlelink"
        :class="{ 'q-sticky-submenu': sticky }"
        :style="sticky && getStickyStyle"
        v-bind="{
            ...(!!baseUrl && { baseUrl }),
        }"
    />
</template>

<script>
import QSubmenu from '@/components/navigation/Submenus/base/QSubmenu.vue'
import NavigationMixin from '@/mixins/NavigationMixin';

export default {
    name: "QDynamicSubmenu",
    props: {
        baseUrl: {
            type: String,
            default: '',
        },
        defaultTitle: {
            type: String,
            default: '',
        },
        defaultButtons: {
            type: Array,
            default: () => [],
        },
        titlelink: {
            type: Array,
            default: null,
        },
        submenu: {
            type: Array,
            default: () => [],
        },
        standardSubmenu: {
            type: Boolean,
            default: false,
        },
        sticky: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        getTitle() {
            return this.parentOfActiveMenuItem?.label || this.defaultTitle
        },
        parentOfActiveMenuItem() {
            return this.findParentOfActiveMenuItem(this.filteredMenu)
        },
        getButtons() {
            const buttons = this.parentOfActiveMenuItem?.children?.map(({ id, to, label, href }) => ({
                id,
                to,
                href,
                label,
                icon: to ? "fas fa-arrow-alt-circle-right" : "fas fa-external-link-alt",
            }));

            return buttons || this.defaultButtons || [];
        },
        getSubmenu() {
            if (this.standardSubmenu) {
                const submenu = [
                    {
                        label: "Help",
                        to: "/support",
                    },
                ];

                return submenu;
            }

            return this.submenu;
        },
        getStickyStyle() {
            return {
                top: this.isMobile ? '74px' : '56px',
            }
        },
    },
    methods: {
        findParentOfActiveMenuItem(items) {
            for (let i = 0; i < items.length; i++) {
                const item = items[i];

                if (item.children.some(({ to }) => to === this.$route.path)) {
                    return item
                }

                const childrenResult = this.findParentOfActiveMenuItem(item.children);

                if(childrenResult) {
                    return childrenResult
                }
            }
        },
    },
    components: {
        QSubmenu,
    },
    mixins: [NavigationMixin],
};
</script>

<style>
.q-sticky-submenu {
    position: sticky;
    z-index: 2;
}
</style>
